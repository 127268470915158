type Props = {
  url: string;
  target?: string;
};

export const openLink = ({ url, target = '_blank' }: Props) => {
  const anchor = document.createElement('a');
  anchor.style.display = 'none';
  anchor.target = target;
  anchor.href = url;
  document.body.appendChild(anchor);

  // Sarafi iOS fix
  window.setTimeout(() => {
    anchor.dispatchEvent(new MouseEvent('click'));

    window.setTimeout(() => {
      document.body.removeChild(anchor);
    }, 100);
  }, 100);
};
